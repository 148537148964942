import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import reducers from './reducers'

const getStore = () =>
  createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  )

export default getStore
