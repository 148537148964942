import React from 'react'
import { ThemeProvider } from '@material-ui/core'
import { Provider } from 'react-redux'

import getStore from './store'
import theme from './theme'

export const wrapPageElement = ({ element }) => {
  const store = getStore()
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </Provider>
  )
}
