import { createMuiTheme } from '@material-ui/core'

const getFontFamily = () => {
  const fonts = ['"Montserrat"', 'sans-serif']
  return fonts.join(',')
}

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none !important',
        '&:hover': {
          boxShadow: 'none !important',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#FF0080',
      contrastText: 'white',
    },
    text: {
      main: '#F0EDEF',
    },
  },
  typography: {
    fontFamily: getFontFamily(),
  },
})

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: '0.875rem',
  lineHeight: '160%',
  color: '#F0EDEF',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.125rem',
  },
}

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '2rem',
  fontWeight: 800,
  lineHeight: '1.98rem',
  letterSpacing: '3%',
  color: '#F0EDEF',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.25rem',
    lineHeight: '2.805rem',
    marginBottom: '2.3125rem',
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '1.75rem',
  lineHeight: '132%',
  letterSpacing: '0.03em',
  color: '#F0EDEF',
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.25rem',
  },
}

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '1.5rem',
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontSize: '2.125rem',
    lineHeight: '2.805rem',
  },
}

theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: '1.25rem',
  fontWeight: 'bold',
  lineHeight: '132%',
  letterSpacing: '0.03em',
}

theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  fontSize: '1.125rem',
  lineHeight: '160%',
  textAlign: 'center',
  color: '#F0EDEF',
  [theme.breakpoints.up('md')]: {
    marginBottom: '2.3125rem',
  },
}

theme.typography.button = {
  ...theme.typography.button,
  fontWeight: 'bold',
  textTransform: 'normal',
  letterSpacing: '0.03em',
}

export default theme
